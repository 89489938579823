import React from "react"

import Layout from "../components/layout"
import Infographic from "../components/infographic"

const ThankYouPage = () => (
    <Layout>
        <div className="thankyou">
            <h1>Thank you!</h1>
            <p className="paragraph-text">Someone from our team will be in touch with you soon.</p>
        </div>
        <Infographic/>
    </Layout>
)

export default ThankYouPage